import React, {FC, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import parse from "html-react-parser";
// @ts-ignore
import TextTruncate from 'react-text-truncate'; // recommend


interface ProductsProps {
}

const Products: FC<ProductsProps> = () => {

    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }

    const params = useParams();
    const [posts, setPosts] = useState<{ products: { id: string; name: string; description: string; thumbnail: string; price: number; category: string }[] }>({products: []});
    useEffect(() => {
        console.log('effect');
        fetch('https://backend.safran-venelledumoulin.fr/api/v1/showcase/category/' + params.id)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setPosts(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [params]);

    return (
        <div className="container mt-2">
            <h2>Produits</h2>
            <div className="row">
                {posts.products.map((post) => {
                    const path = '/produit/' + post.id
                    return (

                        <>
                            <div className="col-md-3">
                                <div className="card mb-2">
                                    <Link className="float-end" to={path}>
                                        <img src={post.thumbnail} className="card-img-top img-fluid"
                                             alt={post.name}/>
                                        <div className="card-body">
                                            <p className="card-text">
                                                <h3 className="text-sm-center"
                                                    color="black">{parse(post.name)}</h3>
                                                <p>
                                                    <TextTruncate line={2} text={post.description}/>
                                                </p>
                                            </p>
                                        </div>
                                        <div className="card-header"><b>Prix : {post.price.toString(10)} € </b><span
                                            className="float-end">Plus d'infos</span></div>
                                    </Link>
                                </div>
                            </div>

                        </>
                    );
                })}
            </div>
        </div>
    );

};

export default Products;
