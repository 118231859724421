import React, {FC, useEffect, useState} from 'react';

import parse from "html-react-parser";

interface RecipesProps {
}

const Recipes: FC<RecipesProps> = () => {
    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }
    const [posts, setPosts] = useState<{ recipes: { title: string; content: string }[] }>({recipes: []});
    useEffect(() => {
        fetch('https://backend.safran-venelledumoulin.fr/api/v1/recipe')
            .then((response) => response.json())
            .then((data) => {
                //console.log(data);{
                setPosts(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    console.log(posts)

    return (
        <div className="container mt-2">

            {posts.recipes.map((post) => {
                // @ts-ignore
                // @ts-ignore
                return (
                    <div className="post-card">
                        <h3 className="post-title">{parse(post.title)}</h3>
                        <p className="post-body">{parse(post.content)}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default Recipes;
