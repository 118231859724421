import React, {FC, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import parse from "html-react-parser";

interface ProductProps {
}

const Product: FC<ProductProps> = () => {

    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }

    const params = useParams();
    const [post, setPosts] = useState<{ id: string, name: string, description: string, price: number, category: string, thumbnail: string}>(
        {
            id: '',
            name:'',
            description:'',
            price: 0,
            category: '',
            thumbnail: ''
        }
    );
    useEffect(() => {
        console.log('effect');
        fetch('https://backend.safran-venelledumoulin.fr/api/v1/showcase/' + params.id)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setPosts(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [params]);

    return (
        <div className="container mt-2 mb-4">
            <h2>{post.name}</h2>
            <div className="row">
                <div className="col-md-6">
                    <img className="img-fluid pimg" alt="Fleur de safran" src={post.thumbnail}/>
                </div>
                <div className="text-justify col-md-6 mt-products">
                    <p>{parse(post.description)}</p>
                    <p>Prix : {post.price.toString(10)} €</p>
                </div>
            </div>
        </div>
    );
}

export default Product;
