import React, {FC} from 'react';

import propriety from '../../assets/img/propr.jpg'
import parcelle from '../../assets/img/parcelle_essai.jpg'
import fleur_exploit from '../../assets/img/fleur_exploitation.jpg'

interface ExploitationProps {
}

const Exploitation: FC<ExploitationProps> = () => {

    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <div className="container mt-2">
            <h2>L&apos;exploitation</h2>

            <div className="row">
                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={propriety}
                         alt="La safrani&egrave;re de la Venelle du Moulin"
                         title="La safrani&egrave;re de la Venelle du Moulin"/>
                    <figcaption className="figure-caption">La safrani&egrave;re de la Venelle du Moulin.</figcaption>
                </figure>
                <div className="text-justify col-md-8 mt-common">
                    <p className="text-justify">
                        C&apos;est au sud du d&eacute;partement du Calvados, &agrave; environ 50Km de CAEN, entre
                        FALAISE et
                        SAINT
                        PIERRE
                        SUR DIVES, dans le village de <a href="contact">JORT</a> que se situe la safrani&egrave;re de la
                        VENELLE DU MOULIN.</p>

                    <p className="text-justify">La r&eacute;flexion sur ce projet d&eacute;bute en 2009. L&apos;ann&eacute;e 2010 est
                        consacr&eacute;e &agrave; la
                        d&eacute;couverte des techniques de culture et de r&eacute;colte. C&apos;est en 2011 que nous mettons
                        en
                        place
                        une
                        parcelle d&apos;essai d&apos;environ 100m&sup2; soit 2000 bulbes dans une commune voisine. Ceci
                        nous
                        permet
                        de juger
                        de l&apos;adaptation de la culture au terrain, de mettre en pratique les m&eacute;thodes
                        glan&eacute;es au
                        cours de
                        mes visites et recherches. Au vu des premiers r&eacute;sultats, je d&eacute;cide
                        d&apos;implanter en
                        2012
                        une
                        parcelle d&apos;environ 1000m&sup2; (20000 bulbes).</p>
                </div>
            </div>

            <div className="row">

                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={parcelle} alt="La parcelle d&apos;essai"
                         title="La parcelle d&apos;essai"/>
                    <figcaption className="figure-caption">La parcelle d&apos;essai.</figcaption>
                </figure>
                <div className="text-justify col-md-8 mt-common">
                    <p className="text-justify">Nous avons choisi d&apos;implanter trois safrani&egrave;res sur une ancienne prairie. Le sol est
                        l&eacute;ger et
                        drainant. Il est expos&eacute; sud-ouest avec une faible d&eacute;clivit&eacute;.</p>
                    <p className="text-justify">En d&eacute;cembre 2011, un labour profond est effectu&eacute; pour b&eacute;n&eacute;ficier des
                        bienfaits
                        du
                        gel. Le sol ne re &ccedil;oit ni engrais ni fumier.</p>
                    <p className="text-justify">En juillet 2012, avant la plantation, le terrain est ameubli puis
                        griff&eacute; et &eacute;pierr&eacute;
                        manuellement.</p>
                    <p className="text-justify">En ao&ucirc;t 2012, d&eacute;bute la plantation. Un sillon est creus&eacute; afin d&apos;y
                        accueillir les
                        bulbes, t&ecirc;te en l&apos;air et bien &agrave; plat. </p>
                </div>
            </div>

            <div className="row">
                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={fleur_exploit}
                         alt="La premi&egrave;re fleur de la safrani&egrave;re de la Venelle du Moulin"
                         title="La premi&egrave;re fleur de la safrani&egrave;re de la Venelle du Moulin"/>
                    <figcaption className="figure-caption">La première fleur.</figcaption>
                </figure>
                <div className="text-justify col-md-8 mt-common">
                    <p className="text-justify">Une fois la culture en place, tr&egrave;s r&eacute;guli&egrave;rement, nous ôtons
                        manuellement
                        les
                        adventices, seuls les bulbes doivent profiter des nutriments du sol.</p>


                    <p className="text-justify">En octobre 2012, les premi&egrave;res fleurs sortent de terre avec un retard d&apos;une quinzaine
                        de
                        jours
                        d&ucirc;
                        &agrave; la pluviom&eacute;trie excessive du mois de septembre. Lorsque la ros&eacute;e est
                        lev&eacute;e, &agrave;
                        peine &eacute;closes, elles sont cueillies et d&eacute;pos&eacute;es d&eacute;licatement dans un
                        panier. </p>
                    <p className="text-justify">Commence alors l&apos;op&eacute;ration minutieuse de l&apos;&eacute;mondage, le pistil est
                        coup&eacute; puis s&eacute;ch&eacute;. </p>

                    <p className="text-justify">L&apos;&eacute;pice est imm&eacute;diatement conditionn&eacute;e dans des pots en verre avec
                        bouchons
                        en
                        li&egrave;ge
                        scell&eacute;s &agrave; la cire et mill&eacute;sim&eacute;s. Ils sont
                        conserv&eacute;s &agrave; l&apos;abri de la
                        lumi&egrave;re.</p>
                </div>
            </div>
        </div>
    );
}
export default Exploitation;
