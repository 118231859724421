import React, {FC} from 'react';
import visit from '../../assets/img/visite.jpg'


interface VisitsProps {
}

const Visits: FC<VisitsProps> = () => {
    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }
    return (
        <div className="container mt-2">
            <h2>Les visites</h2>
            <div className="row">
                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={visit}
                         alt="La safrani&egrave;re de la Venelle du Moulin"
                         title="La safrani&egrave;re de la Venelle du Moulin"/>
                    <figcaption className="figure-caption">La safrani&egrave;re de la Venelle du Moulin.</figcaption>
                </figure>
                <div className="text-justify col-md-8 mt-common">

                    <h3>Horaires d'ouverture :</h3>
                    <ul>
                        <li>Visites guid&eacute;es : sur r&eacute;servation</li>
                        <li>Possibilit&eacute; de visite de groupe (&agrave; partir de 15 personnes) sur
                            r&eacute;servation
                        </li>
                    </ul>
                    <h3>Tarif de la visite :</h3>
                    <ul>
                        <li>Adulte : 5 &#x20AC;</li>
                        <li>Enfant de moins de 12 ans accompagn&eacute; d'un adulte: gratuit</li>
                        <li>Tarif de groupe : 4 &#x20AC;</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Visits;
