import React, {FC} from 'react';
import {MdMap, MdOutlineMail, MdOutlinePhoneAndroid} from "react-icons/md";
import {Link} from "react-router-dom";

interface FooterProps {
}

const Footer: FC<FooterProps> = () => (
    <div className="footer"
         style={{
             backgroundImage: `url(${require('../../assets/img/flower.png')})`,
             backgroundPosition: 'left',
             backgroundRepeat: 'no-repeat',
         }}>
        <div className="text-center">
            <div className="row text-center">
                <p>
                    <MdOutlinePhoneAndroid/> 06 70 22 21 23 - <MdOutlineMail/> <a
                    className="text-white"
                    href="mailto:safraniere.venelle.moulin@gmail.com">safraniere.venelle.moulin@gmail.com</a>
                    - <MdMap/> 21 rue du lieutenant Paul Duhomme 14170 Jort - <Link className="text-white" to="/legals"> Mentions légales </Link>
                </p>
            </div>
        </div>
    </div>
);

export default Footer;
