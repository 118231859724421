import React, {FC} from 'react';

interface LegalsProps {
}

const Legals: FC<LegalsProps> = () => (
    <div className="container mt-2">
        <h2> Mentions légales </h2>
        <h3>Hébergeur</h3>
        OVH
        Siège social : 2 rue Kellermann - 59100 Roubaix - France.


        <h3>Conditions d'utilisation du site Internet</h3>

        L'ensemble de ce site relève des législations française et internationale sur le droit d'auteur et la propriété
        intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et
        photographiques.


        <h3>Données personnelles</h3>

        D'une façon générale, vous pouvez visiter notre site sur Internet sans avoir à décliner votre identité et à
        fournir des informations personnelles vous concernant. Cependant, nous pouvons parfois vous demander des
        informations
    </div>
);

export default Legals;
