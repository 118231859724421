import React, {FC, useEffect, useState} from 'react';
import fleur from '../../assets/img/fleur_actu.jpg'
import parse from 'html-react-parser'
import {MdFacebook} from "react-icons/md";
import {Link} from "react-router-dom";

interface NewsProps {
}


const News: FC<NewsProps> = () => {

    const currentURL = window.location.href
    if (currentURL.endsWith('actus')) {
        const element = document.getElementById('main');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        }
    }
    console.log(currentURL)


    const [posts, setPosts] = useState<{ news: { title: string; content: string }[] }>({news: []});
    useEffect(() => {
        fetch('https://backend.safran-venelledumoulin.fr/api/v1/news')
            .then((response) => response.json())
            .then((data) => {
                //console.log(data);{
                setPosts(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div className="container mt-2">
            <h2>Présentation</h2>
            <div className="row">
                <div className="col-md-3">
                    <img className="img-fluid pimg" alt="Fleur de safran" src={fleur}/>
                </div>
                <div className="text-justify col-md-8 mt-presentation">
                    La safranière de la Venelle du Moulin ouvre ses portes en Octobre 2013. Elle permet la découverte
                    d'un
                    produit noble qui peut être utilisé de manière simple au quotidien, <Link to="/safran">le Safran.</Link>
                    <br/>
                    Une découverte de la safranière est possible. Un magasin de vente à la ferme est également
                    accessible. N'hésitez pas à nous contacter nous vous ouvrirons le magasin.
                    Des visites guidées de la safranière sont possibles sur rendez-vous. Vous pouvez nous appeller au
                    06.70.22.21.23.
                    <br/>
                    Vous pouvez également nous retrouver sur notre page <a href="https://www.facebook.com/SafraniereVenelleduMoulin"  rel="noreferrer"  target="_blank"><MdFacebook/>Facebook</a>.
                </div>
            </div>
            <h2 className="mt-2">Actualités</h2>
            <div className="container mt-2">
                {posts.news.map((post) => {
                    // @ts-ignore
                    // @ts-ignore
                    return (
                        <div className="post-card">
                            <h3 className="post-title">{parse(post.title)}</h3>
                            <p className="post-body">{parse(post.content)}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default News;
