import React, {FC} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../assets/img/logo.png'
import logoLum from '../../assets/img/logoLum.png'
import {Link} from "react-router-dom";

interface MyNavbarProps {
}

const MyNavbar: FC<MyNavbarProps> = () => {

    const handleClickScroll = () => {
        const element = document.getElementById('main');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Navbar className="fixed-top navbar-light" bg="white" expand="lg">
                <Container>
                    <Navbar.Brand href="#"><img src={logo} height="36" alt="Logo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link><Link to="/actus">Accueil</Link></Nav.Link>
                            <Nav.Link><Link to="/safran">Le Safran</Link></Nav.Link>
                            <Nav.Link><Link to="/exploitation">L'exploitation</Link></Nav.Link>
                            <Nav.Link><Link to="/chambres">Nos chambres d'hôtes</Link></Nav.Link>
                            <Nav.Link><Link to="/recettes">Nos Recettes</Link></Nav.Link>
                            <NavDropdown title="Nos produits" id="showroom-dropdown">
                                <NavDropdown.Item href="#/produits/All">Tous les produits</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Safran">Safran</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Confitures">Confitures</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Gelées">Gelées</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Confits">Confits</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Confiseries">Confiseries</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Miels">Miels</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Sirops">Sirops</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Condiments">Condiments</NavDropdown.Item>
                                <NavDropdown.Item href="#/produits/Coffrets">Coffrets</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link><Link to="/visites">Les visites</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div
                style={{
                    backgroundImage: `url(${require('../../assets/img/banniere.jpg')})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
                className="vh-100">
                <img className="mx-auto d-block img-fluid headImg" src={logoLum} alt="Logo"/>
            </div>

            <div className=" button scroll-down" onClick={handleClickScroll}>

                    <div className="scroll-down-text"> Venez découvrir l'univers du safran avec nous</div>
                    <div className="scroll-down-bar"></div>

            </div>
        </>
    );
}

export default MyNavbar;
