import React, {FC} from 'react';

import ext from '../../assets/img/ext.jpg'
import auge from '../../assets/img/pays_dauge.jpg'
import bf from '../../assets/img/breakfast.jpg'

interface RoomsProps {
}

const Rooms: FC<RoomsProps> = () => {
    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <div className="container mt-2">
            <h2>Chambres d'hôtes</h2>
            <div className="row">

                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={ext}
                         alt="Jardin d'été de la safrani&egrave;re de la Venelle du Moulin"
                         title="Jardin d'été de la safrani&egrave;re de la Venelle du Moulin"/>
                    <figcaption className="figure-caption">Jardin d'été de la Safranière de la Venelle du Moulin.
                    </figcaption>
                </figure>
                <div className="text-justify col-md-8 mt-common">
                    <p className="text-justify">
                        Idéalement situé au carrefour du Pays d'Auge et de la plaine de Caen, venez profiter du charme
                        normand
                        chez Isabelle et Marc.
                    </p>
                    <p className="text-justify">
                        Au coeur du domaine de la Safranière, le doux bruit de la Dives bordant les cultures de safran
                        vous
                        bercera.
                        Ce cadre authentique sera idéal pour vous ressourcer.
                    </p>

                    <p className="text-justify">
                        Vous flânerez dans le jardin fleuri et arboré avant de rejoindre votre
                        chambre à l'aménagement unique.
                    </p>
                    <p className="text-justify">
                        Confortablement installés dans votre salon, vous apprécierez les équipements mis à votre
                        disposition
                        (plateau bouilloire, télévision, wifi gratuit, ...).
                    </p>

                </div>
            </div>
            <div className="row">
                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={auge}
                         alt="Chambre Pays d'Auge"
                         title="Chambre Pays d'Auge"/>
                    <figcaption className="figure-caption">Chambre Pays d'Auge.</figcaption>
                </figure>

                <div className="text-justify col-md-8 mt-common">

                    <p className="text-justify">
                        Chaque matin, Isabelle vous servira un petit déjeuner composé de produits faits maison et issus
                        de
                        l'agriculture locale (Vous aurez la possibilité de demander un petit déjeuner sans gluten).
                    </p>
                    <p className="text-justify">
                        Terroir et Authenticité seront au cœur de votre séjour.
                    </p>
                </div>
            </div>
            <h2>Réservation</h2>
            <div className="row">

                <figure className="figure text-center d-block col-md-4">
                    <img className="img-fluid pimg" src={bf}
                         alt="Petit déjeuner"
                         title="Petit déjeuner"/>
                    <figcaption className="figure-caption">Petit déjeuner.</figcaption>
                </figure>

                <div className="text-justify col-md-8 mt-common">
                    <p className="text-justify">
                        Réservation au 06 70 22 21 23 ou par mail <a
                        href="mailto://safraniere.venelle.moulin@gmail.com">safraniere.venelle.moulin@gmail.com</a>.
                        La réservation pour les tables d'hôtes doit se faire 24h à l'avance.
                    </p>
                    <ul>
                        <li>Haute saison du 1er Avril au 30 Septembre</li>
                        <li>Basse saison du 1er Novembre au 31 Mars</li>
                    </ul>

                    <p className="text-justify">
                        Avez-vous pensez à offrir un week-end dans nos chambres à vos amis ou votre famille? Nous
                        proposons l'ensemble de nos prestations sous la forme de chèques cadeaux.
                    </p>

                </div>
            </div>

            <h2>Tarifs</h2>

            <h3>Tarifs des chambres d'hôtes</h3>
            <div className="table-responsive">
                <table className="table table-sm table-striped">
                    <thead className="table-dark">
                    <tr>
                        <th>Date</th>
                        <th>Prix</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Haute saison pour 2 personnes</td>
                        <td>140€ par nuitée TTC</td>
                    </tr>
                    <tr>
                        <td>Basse saison pour 2 personnes</td>
                        <td>115€ par nuitée TTC</td>
                    </tr>
                    <tr>
                        <td>Haute saison pour 1 personnes</td>
                        <td>130€ par nuitée TTC</td>
                    </tr>
                    <tr>
                        <td>Basse saison pour 1 personnes</td>
                        <td>105€ par nuitée TTC</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p className="float-end">Petit déjeuner inclus</p>
            <h3 className="mt-4">Tarifs de la table d'hôtes</h3>
            <div className="table-responsive">
                <table className="table table-sm table-striped">
                    <thead className="table-dark">
                    <tr>
                        <th>Date</th>
                        <th>Prix</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Assiette gourmande (boissons incluses)</td>
                        <td>22€ par personne TTC</td>
                    </tr>
                    <tr>
                        <td>Repas à la table d'hôtes (entrée, plat, fromage, dessert et boissons)</td>
                        <td>32€ par personne TTC</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Rooms;
