import React from 'react';

import './App.scss';
import CookieConsent from "react-cookie-consent";
import {HashRouter as Router, Route, Routes} from 'react-router-dom'
import MyNavbar from "./components/MyNavbar/MyNavbar";
import LeSafran from "./components/LeSafran/LeSafran";
import Footer from "./components/Footer/Footer";
import Visits from "./components/Visits/Visits";
import Rooms from "./components/Rooms/Rooms";
import Exploitation from "./components/Exploitation/Exploitation";
import News from "./components/News/News";
import Recipes from "./components/Recipes/Recipes";
import Products from "./components/Products/Products";
import Legals from './components/Legals/Legals';
import Product from "./components/Product/Product";


class App extends React.Component {


    render() {
        return (
            <Router>
                <div className="App">
                    <header className="App-header">
                        <MyNavbar></MyNavbar>
                    </header>
                    <main id="main">
                        <Routes>
                            <Route path="/" element={<News/>}/>
                            <Route path="/actus" element={<News/>}/>
                            <Route path="/legals" element={<Legals/>}/>
                            <Route path="/produit/:id" element={<Product/>}/>
                            <Route path="/safran" element={<LeSafran/>}/>
                            <Route path="/chambres" element={<Rooms/>}/>
                            <Route path="/exploitation" element={<Exploitation/>}/>
                            <Route path="/visites" element={<Visits/>}/>
                            <Route path="/recettes" element={<Recipes/>}/>
                            <Route path="/produits/:id" element={<Products/>}/>
                        </Routes>
                    </main>
                    <footer>
                        <Footer></Footer>
                    </footer>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText="J'ai compris!!"
                    cookieName="safranieredelavenelledumoulinconsent"
                    style={{background: "#650503"}}
                    buttonStyle={{background: "#FFB891", fontSize: "13px"}}
                    expires={150}
                >
                    Ce site n'utilise que des cookies techniques et ne collecte pas vos données personelles.{" "}
                    <span style={{fontSize: "10px"}}>Et nous ne voulions pas vous le cacher :O</span>
                </CookieConsent>
            </Router>
        );
    }
    ;
}

export default App;
