import React, {FC} from 'react';
import fresque from '../../assets/img/fresque_histoire.jpg'
import bulbe from '../../assets/img/photo_bulbe.jpg'
import fleur from '../../assets/img/fleur_botanic.jpg'
import enemi from '../../assets/img/ennemi2.jpg'
import emondage from '../../assets/img/emondage.jpg'
import bienChoisir from '../../assets/img/bien_choisir.jpg'
import production from '../../assets/img/production.png'
import bienfaits from '../../assets/img/bienfait.jpg'
import floraison from '../../assets/img/floraison.jpg'
import plan from '../../assets/img/plan.jpg'

interface LeSafranProps {
}

const LeSafran: FC<LeSafranProps> = () => {

    const element = document.getElementById('main');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <>
            <div className="container mt-2 text-justify">
                <h2>Histoire et L&eacute;gende</h2>
                <div className="row">
                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={fresque}
                             alt="La fresque de Th&eacute;ra"
                             title="La fresque de Th&eacute;ra"/>
                        <figcaption className="figure-caption">Cueilleurs de Safran.</figcaption>
                    </figure>
                    <div className="text-justify col-md-8 mt-common">
                        <p className="text-justify">La Perse antique est consid&eacute;r&eacute;e comme le berceau du safran. Les
                            premi&egrave;res
                            cultures
                            sont
                            apparues il y a plus de 5000 ans sur les plateaux perses et sur les hautes vall&eacute;es du
                            Cachemire.</p>
                        <p className="text-justify">Dans la culture gr&eacute;co-romaine, la plus ancienne repr&eacute;sentation date de 1600 ans
                            avant
                            J&eacute;sus-Christ.
                            Une fresque minoenne &laquo; Cueilleurs de Safran &raquo;, repr&eacute;sentant deux femmes
                            ramassant
                            des
                            fleurs
                            de
                            Crocus a &eacute;t&eacute; d&eacute;couverte dans les fouilles d&apos;Akrotiri, sur
                            l&apos;&icirc;le &eacute;g&eacute;enne
                            de Santorin.</p>

                        <p className="text-justify">La l&eacute;gende la plus connue est l&apos;histoire de SMILAX et CROCUS. Dans les bois
                            d&apos;Ath&egrave;nes, un
                            bel et jeune homme, appel&eacute; CROCUS, suit une nymphe SMILAX. Apr&egrave;s une courte
                            p&eacute;riode
                            d&apos;amour,
                            SMILAX se lasse des attentions de son amant. Elle finit par l&apos;ensorceler et le
                            transformer
                            en
                            fleur &agrave;
                            safran. Les stigmates orange flamboyant symbolisent la passion immortelle de CROCUS pour
                            SMILAX.</p>
                        <p className="text-justify">Au IXe si&egrave;cle, les arabes ont introduit des bulbes de safran en Afrique du Nord, puis
                            en
                            Espagne.
                            L&apos;apparition
                            en France date du XIIe si&egrave;cle, elle est li&eacute;e au retour des croisades et
                            aux &eacute;changes
                            commerciaux avec l&apos;Orient.</p>
                    </div>
                </div>

                <h2>Botanique</h2>
                <div className="row">

                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={bulbe} alt="Crocus Savitus"
                             title="Crocus Savitus"/>
                        <figcaption className="figure-caption">Bulbe de Crocus Savitus.</figcaption>
                    </figure>

                    <div className="text-justify col-md-8 mt-common">

                        <p className="text-justify">Le crocus &agrave; safran de la Venelle du Moulin s&apos;appelle CROCUS SATIVUS (du
                            grec <i>Crocus</i> :
                            safran et
                            du latin <i>Sativus</i> : culture). Il appartient &agrave; la famille
                            des <i>Iridacees</i> et &agrave; l&apos;ordre
                            des <i>Liliacees</i>. Il existe d&apos;autres vari&eacute;t&eacute;s de crocus qui sont
                            cependant
                            beaucoup moins
                            productives comme le <i>Crocus Carwrigthianus</i>, le <i>Crocus Hadriatus</i> et le <i>Crocus
                                Niveus</i>.
                        </p>

                        <h3>Bulbe</h3>
                        <p className="text-justify">Le <i>Crocus Sativus</i> est une plante &agrave; bulbes. Cette partie de la plante
                            est &eacute;galement
                            appel&eacute;e
                            <i>cormus</i>. Il est arrondi dessus, aplati dessous et son diam&egrave;tre varie de
                            2,5 &agrave; 5
                            cm.
                            Il est
                            recouvert d&apos;un amas de filasses. On observe des petits yeux qui donneront
                            naissance &agrave; des
                            bouquets de
                            feuilles. Certains bouquets cachent, en leur centre une hampe florale. </p>

                        <h3>Feuille</h3>
                        <p className="text-justify"> Les feuilles sont longues, fines, vertes, brillantes et se terminent en pointes. Elles sont
                            caduques.
                            Elles
                            apparaissent avant ou apr&egrave;s la floraison. Elles sont en croissance
                            d&apos;octobre &agrave; mai
                            puis
                            jaunissent et dess&egrave;chent en mai-juin. En juillet, elles ont totalement disparu.</p>

                    </div>
                </div>
                <div className="row">
                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={fleur} alt="Crocus Savitus"
                             title="Crocus Savitus"/>
                        <figcaption className="figure-caption">Fleur de Crocus Savitus.</figcaption>
                    </figure>


                    <div className="text-justify col-md-8 mt-common">
                        <h3>Fleur</h3>
                        <p className="text-justify"> C&apos;est une fleur hermaphrodite de 7 &agrave; 12 cm. Elle est form&eacute;e de 6
                            t&eacute;pales
                            violets (3 p&eacute;tales
                            et 3 s&eacute;pales), de 3 &eacute;tamines jaunes charg&eacute;es de pollen et d&apos;un
                            pistil
                            blanc &agrave; la
                            base qui se divise en 3 stigmates rouge vif, brillants, dentel&eacute;s, pliss&eacute;s. Ces
                            stigmates
                            sont longs de
                            3 &agrave; 5 cm et tr&egrave;s parfum&eacute;s. C&apos;est par le s&eacute;chage du pistil
                            que
                            l&apos;on
                            obtient le
                            safran.</p>

                        <h3>Multiplication</h3>
                        <p className="text-justify"> Elle se fait l&apos;hiver apr&egrave;s la floraison. C&apos;est une multiplication
                            v&eacute;g&eacute;tative. Un
                            bulbe donne naissance &agrave; de nouveaux bulbes sur sa partie sup&eacute;rieure puis
                            meurt.
                            Ces
                            jeunes
                            bulbilles
                            croissent au printemps et se multiplieront &agrave; leur tour. Tous les cinq ou six ans,
                            l&apos;intervention de l&apos;homme
                            est n&eacute;cessaire pour arracher, calibrer et replanter afin d&apos;&eacute;viter
                            un &eacute;touffement qui
                            serait fatal &agrave; la plante.</p>
                    </div>
                    <div className="row">
                        <figure className="figure text-center d-block col-md-4">
                            <img className="img-fluid pimg" src={floraison} alt="Crocus Savitus"
                                 title="Crocus Savitus"/>
                            <figcaption className="figure-caption">Fleurs de Crocus Savitus.</figcaption>
                        </figure>


                        <div className="text-justify col-md-8 mt-common">
                            <h3>Floraison </h3>
                            <p className="text-justify"> C&apos;est une plante &agrave; v&eacute;g&eacute;tation invers&eacute;e
                                et &agrave; floraison
                                automnale.
                                Les
                                fleurs apparaissent de fin septembre &agrave; fin novembre avec un pic autour du 15
                                octobre.
                                Les
                                premi&egrave;res
                                &eacute;closent lorsque la temp&eacute;rature nocturne avoisine les 10&deg;C.
                                Les fleurs sortent de terre la nuit et s&apos;&eacute;panouissent le lendemain matin.
                                Elles
                                sortent
                                de
                                terre prot&eacute;g&eacute;es
                                par une gaine blanche qui enveloppe les feuilles. La dur&eacute;e de vie de ces fleurs
                                est
                                de 24
                                heures. </p>
                            <h3>Confusion</h3>
                            <p className="text-justify">Le crocus &agrave; safran est une plante cultiv&eacute;e que l&apos;on ne peut
                                trouver &agrave; l&apos;&eacute;tat
                                sauvage. Par contre &agrave; l&apos;automne, dans les sous-bois, on peut rencontrer des
                                fleurs
                                mauves
                                assez
                                semblables, il s&apos;agit de colchiques. Le colchique d&apos;automne est
                                reconnaissable &agrave; son
                                pistil blanc.
                                Il contient un poison violent, la colchicine (n&eacute;anmoins
                                utilis&eacute;e &agrave; tr&egrave;s
                                faibles doses en
                                pharmacie).
                            </p>
                        </div>
                    </div>
                </div>

                <h2>La culture </h2>
                <div className="row">
                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={plan} alt="Plans de Crocus Savitus"
                             title="Crocus Savitus"/>
                        <figcaption className="figure-caption">Plans de Crocus Savitus.</figcaption>
                    </figure>


                    <div className="text-justify col-md-8 mt-common">
                        <p className="text-justify">Le safran est obtenu &agrave; partir de fleurs de Crocus. St&eacute;rile, la plante ne
                            produit
                            pas de
                            graines
                            viables et la reproduction ne peut se faire que par la multiplication des bulbes. La survie
                            de
                            la
                            plante
                            est
                            totalement d&eacute;pendante de l&apos;homme.</p>
                        <h3>Le sol </h3>
                        <p className="text-justify">Le crocus pr&eacute;f&egrave;re les sols l&eacute;gers, drainants, riches avec un pH autour
                            de
                            6,5-7.
                            L&apos;id&eacute;al
                            est un sol argilo-calcaire ou argilo-sableux. Il faut &eacute;viter les sols
                            imperm&eacute;ables,
                            humides et
                            compacts. L&apos;exc&egrave;s de pierres nuit &eacute;galement au d&eacute;veloppement de la
                            culture.</p>
                        <h3>L&apos;exposition</h3>
                        <p className="text-justify">L&apos;id&eacute;al est une exposition plein sud ou sud-est sur un terrain en pente.</p>
                        <h3>Calibre des bulbes</h3>
                        <p className="text-justify"> Il correspond au p&eacute;rim&egrave;tre en centim&egrave;tres du bulbe. Le calibre
                            optimal &agrave; la
                            plantation
                            est de 8 et plus.</p>
                    </div>
                </div>
                <div className="row">
                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={enemi} alt="Un ennemi"
                             title="Un ennemi"/>
                        <figcaption className="figure-caption">Un énnemi du Safran.</figcaption>
                    </figure>
                    <div className="text-justify col-md-8 mt-common">

                        <h3>Maladies</h3>
                        <p className="text-justify">Les safrani&egrave;res peuvent &ecirc;tre atteintes par le rhyzoctone violet,
                            appel&eacute; &laquo; mort
                            du safran
                            &raquo;, qui entraine le pourrissement du bulbe. D&apos;autres maladies fongiques peuvent
                            toucher la
                            culture en
                            place ou les bulbes stock&eacute;s (ex: la fusariose).

                            Des mesures pr&eacute;ventives permettent de limiter le risque de d&eacute;veloppement de
                            ces
                            maladies
                            telles que :
                            <ul>
                                <li>Plantation de bulbes sains</li>
                                <li>Lavage des bulbes &agrave; l&apos;arrachage pour l&apos;&eacute;limination des
                                    spores,
                                    destruction des bulbes
                                    douteux
                                </li>
                                <li>Conservation des bulbes &agrave; l&apos;obscurit&eacute;, dans un endroit sec et
                                    a&eacute;r&eacute;</li>
                                <li>Pas de plantation de crocus apr&egrave;s des cultures pouvant &ecirc;tre atteintes
                                    de
                                    fusariose
                                    (c&eacute;r&eacute;ales,
                                    pommes de terre&hellip;)
                                </li>
                                <li>Respect de la rotation des cultures qui doit &ecirc;tre de 6 &agrave; 10 ans</li>
                            </ul>
                        </p>
                        <h3>Les ennemis</h3>
                        <p className="text-justify">
                            De petits pr&eacute;dateurs sont amateurs de bulbes et de jeunes pousses. Les limaces
                            s&apos;attaquent aux feuilles.
                            Les mulots et campagnols se d&eacute;lectent des bulbes. Les vers gris (larves de
                            noctuelles),
                            vers
                            fil de fer
                            (larves de taupin) et vers blancs (larves de hannetons) se r&eacute;galent de jeunes
                            pousses,
                            bulbes
                            et fleurs.
                            Une partie des ces nuisibles sont naturellement d&eacute;truits par le travail du sol. Des
                            app&acirc;ts biologiques
                            sont aussi mis en place sur les parcelles.
                        </p>
                    </div>
                    <div className="row">
                        <figure className="figure text-center d-block col-md-4">
                            <img className="img-fluid pimg" src={emondage}
                                 alt="L&apos;&eacute;mondage"
                                 title="L&apos;&eacute;mondage"/>
                            <figcaption className="figure-caption">L&apos;&eacute;mondage</figcaption>
                        </figure>
                        <div className="text-justify col-md-8 mt-common">
                            <h3>La r&eacute;colte</h3>
                            <p className="text-justify"> Enti&egrave;rement manuelle, elle est r&eacute;alis&eacute;e quotidiennement de fin
                                septembre &agrave; novembre.
                                Il faut &eacute;viter de laisser les fleurs sous le soleil afin de pr&eacute;server la
                                qualit&eacute; de l&apos;&eacute;pice.</p>
                            <h3>L&apos;&eacute;mondage </h3>
                            <p className="text-justify">Cette op&eacute;ration consiste &agrave; s&eacute;parer le pistil de la fleur. Cette
                                phase
                                est
                                minutieuse et d&eacute;licate.
                                Pour chaque fleur, les corolles sont &eacute;cart&eacute;es et le pistil coup&eacute;.
                                Les
                                p&eacute;tales et les s&eacute;pales
                                fanent et se d&eacute;composent en quelques heures.</p>

                            <h3>Le s&eacute;chage</h3>
                            <p className="text-justify"> Il s&apos;agit l&agrave; aussi d&apos;une phase complexe et importante. Elle conditionne
                                la
                                qualit&eacute;
                                (couleur, odeur et ar&ocirc;me) et la conservation du safran. Les techniques de
                                s&eacute;chage
                                sont diff&eacute;rentes
                                d&apos;une exploitation &agrave; l&apos;autre. Les filaments peuvent
                                s&eacute;cher &agrave; l&apos;air libre,
                                abrit&eacute;s ou non du soleil, au four, au s&eacute;choir &agrave; pollen ou en
                                dessiccateur.
                                Le safran aura une saveur &eacute;pic&eacute;e si le s&eacute;chage est long (air libre)
                                ou
                                safran&eacute;e si le
                                s&eacute;chage est rapide (apport r&eacute;gulier de chaleur). </p>
                            <p className="text-justify">Le conditionnement est r&eacute;alis&eacute; d&egrave;s la fin du s&eacute;chage.
                                L&apos;&eacute;pice doit
                                &ecirc;tre conserv&eacute;e &agrave; l&apos;obscurit&eacute;. Un d&eacute;lai de deux
                                mois
                                doit &ecirc;tre respect&eacute;
                                avant utilisation. Bien stock&eacute;, le safran garde ses qualit&eacute;s
                                organoleptiques
                                pendant 4 ann&eacute;es.</p>
                        </div>
                    </div>
                </div>

                <h2>Comment bien choisir son safran ?</h2>
                <div className="row">

                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={bienChoisir} alt="Safran"
                             title="Safran"/>
                        <figcaption className="figure-caption">Des pitstils de Safran.</figcaption>
                    </figure>


                    <div className="text-justify col-md-8 mt-common">
                        <p className="text-justify">Le prix &eacute;lev&eacute; du safran donne toute latitude &agrave; la contrefa&ccedil;on. En
                            poudre,
                            il est plus
                            facile &agrave; falsifier, il est donc imp&eacute;ratif de privil&eacute;gier celui en
                            filaments.
                            Ces derniers
                            doivent &ecirc;tre longs, fins, rouge intense et en forme de trompe. Le mill&eacute;sime
                            doit
                            obligatoirement figur&eacute;
                            sur l&apos;emballage.</p>

                        <p className="text-justify">Une analyse de laboratoire (norme ISO 3632) permet de d&eacute;terminer les valeurs
                            qualitatives
                            du
                            safran.</p>

                        <p className="text-justify"> On mesure :
                            <ul>
                                <li>La crocine : le pouvoir colorant (l&apos;intensit&eacute; de la couleur)</li>
                                <li>Le safranal : le pouvoir odorant (ar&ocirc;me)</li>
                                <li>La picrocrocine : le go&ucirc;t (amertume)</li>
                                <li>La teneur en eau</li>
                            </ul>
                            <p className="text-justify">La mesure de ces quatre crit&egrave;res permet la classification du safran en trois
                                cat&eacute;gories.</p>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm text-center">
                                    <thead className="table-dark">
                                    <tr>
                                        <th scope="col"> Cat&eacute;gorie</th>
                                        <th scope="col"> Picrocrocine</th>
                                        <th scope="col"> Safranal</th>
                                        <th scope="col"> Crocine</th>
                                        <th scope="col"> Teneur en eau</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td> I</td>
                                        <td> Min 70</td>
                                        <td> 20-50</td>
                                        <td> Min 200</td>
                                        <td> Max 12%</td>
                                    </tr>
                                    <tr>
                                        <td> II</td>
                                        <td> Min 55</td>
                                        <td> 20-50</td>
                                        <td> Min 170</td>
                                        <td> Max 12%</td>
                                    </tr>
                                    <tr>
                                        <td> III</td>
                                        <td> Min 40</td>
                                        <td> 20-50</td>
                                        <td> Min 120</td>
                                        <td> Max 12%</td>
                                    </tr>
                                    </tbody>

                                </table>
                            </div>
                        </p>

                        <p className="text-justify">Cette analyse scientifique exclut la notion de terroir.</p>
                    </div>
                </div>

                <h2> Quelques chiffres</h2>
                <div className="row">

                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={production} alt="La production mondiale"
                             title="La production mondiale"/>
                        <figcaption className="figure-caption">La r&eacute;partition de la production dans le monde.
                        </figcaption>
                    </figure>

                    <div className="text-justify col-md-8 mt-common">

                        <h3>Les principaux pays producteurs</h3>
                        <p className="text-justify"> La plus grande partie de la production provient d&apos;une large ceinture
                            s&apos;&eacute;tendant
                            du
                            Maroc &agrave;
                            l&apos;Inde.
                            L&apos;Iran est le premier producteur mondial avec 90% de la production, essentiellement
                            export&eacute;e. </p>
                        <p className="text-justify">L&apos;Inde et plus particuli&egrave;rement la province du Cachemire, vient en
                            deuxi&egrave;me
                            position (production
                            autoconsomm&eacute;e). Ensuite, on trouve la Gr&egrave;ce dont la zone de culture se situe
                            en
                            Mac&eacute;doine
                            autour de la ville de Krokos (du nom de la fleur de crocus), puis le Maroc et
                            l&apos;Italie.</p>
                        <p className="text-justify">La France a &eacute;t&eacute; l&apos;un des plus gros producteurs au XVIIe si&egrave;cle. La
                            production &eacute;tait
                            concentr&eacute;e dans le Gatinais (entre Montargis et Pithiviers). Suite &agrave; un hiver
                            rigoureux puis &agrave;
                            l&apos;exode rural les derniers champs ont disparu en 1930.
                            Actuellement la production fran&ccedil;aise est tr&egrave;s faible.</p>

                        <p className="text-justify">Faute d&apos;organisme f&eacute;d&eacute;rateur mondial, les donn&eacute;es sont tr&egrave;s
                            fluctuantes suivant
                            les sources.</p>
                    </div>
                </div>

                <h2>Les bienfaits du safran</h2>
                <div className="row">

                    <figure className="figure text-center d-block col-md-4">
                        <img className="img-fluid pimg" src={bienfaits} alt="La fresque de Th&eacute;ra"
                             title="La fresque de Th&eacute;ra"/>
                        <figcaption className="figure-caption">Du thé au Safran.</figcaption>
                    </figure>

                    <div className="text-justify col-md-8 mt-common">
                        <h3> Utilisation en m&eacute;decine </h3>
                        <p className="text-justify">L&apos;utilisation du safran comme plante m&eacute;dicinale est l&eacute;gendaire. </p>

                        <p className="text-justify">Il est cit&eacute; dans d&apos;anciens textes m&eacute;dicaux chinois comme le
                            trait&eacute; de
                            Bencao Gangmu r&eacute;dig&eacute;
                            par le Docteur Li Shizhen (M&eacute;decin herboriste et naturaliste) vers 1560.</p>
                        <p className="text-justify">En Europe m&eacute;di&eacute;vale, de nombreuses infections &eacute;taient trait&eacute;es
                            avec
                            les
                            pistils de
                            safran. En effet, les maladies comme les indigestions, la goutte ou encore
                            l&apos;insomnie &eacute;taient soign&eacute;es
                            avec cette plante.</p>


                        <p className="text-justify">Pour les perses et les &eacute;gyptiens, le safran &eacute;tait aussi un aphrodisiaque et un
                            antidote
                            contre les
                            empoisonnements. </p>

                        <p className="text-justify"> En Iran, le th&eacute; au safran a la r&eacute;putation d&apos;am&eacute;liorer
                            l&apos;humeur.
                            Il
                            est &eacute;galement
                            efficace dans le traitement de la d&eacute;pression</p>
                        <p className="text-justify">Quand le safran est appliqu&eacute; localement, il a un r&ocirc;le anti-inflammatoire et
                            anti-douleur(pouss&eacute;e
                            dentaire). </p>
                    </div>
                </div>  
            </div>
        </>
    );
}

export default LeSafran;
